import { render, staticRenderFns } from "./AlertsView.vue?vue&type=template&id=48bdebb7&scoped=true&"
import script from "./AlertsView.vue?vue&type=script&lang=ts&"
export * from "./AlertsView.vue?vue&type=script&lang=ts&"
import style0 from "./AlertsView.vue?vue&type=style&index=0&id=48bdebb7&prod&lang=scss&scoped=true&"
import style1 from "./AlertsView.vue?vue&type=style&index=1&id=48bdebb7&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48bdebb7",
  null
  
)

export default component.exports