
import FixedProgressBar from '@/components/items/FixedProgressBar.vue';
import { Product } from '@/store/models/Product';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
  components: {
    FixedProgressBar
  }
})
export default class AlertsCardList extends Vue {
  @PropSync('items') sortedProducts!: Product[];
  @Prop() loadingProducts!: boolean;

  public didSelectCard(selectedItems: Product[], id: number, entityId: number) {
    this.$emit('card-selected', selectedItems, id, entityId);
  }

  public openActionModal(product: Product) {
    this.$emit('openActionModal', product);
  }

  public openEditModal(product: Product) {
    this.$emit('openEditModal', product);
  }
}
