
import AlertsFilters from '@/components/alerts/AlertsFilters.vue';
import DraggablePanel from '@/components/buttons/DraggablePanel.vue';
import LastUpdated from '@/components/common/LastUpdated.vue';
import MobileHeader from '@/components/common/MobileHeader.vue';
import Searchbar from '@/components/common/Searchbar.vue';
import AlertsCardList from '@/components/list/AlertsCardList.vue';
import ListView from '@/components/list/ListView.vue';
import AppName from '@/components/mixin/AppName.vue';
import ConstantsMixin from '@/components/mixin/Constants.vue';
import FeedFiltersModal from '@/components/modal/FeedFiltersModal.vue';
import FuelFiltersModal from '@/components/modal/FuelFiltersModal.vue';
import MilkFiltersModal from '@/components/modal/MilkFiltersModal.vue';
import SideContent from '@/components/sidebar/SideContent.vue';
import TableSearchbar from '@/components/table/TableSearchbar.vue';
import { trackEvent } from '@/services/Mixpanel';
import { setHasNonFonterra, storeState } from '@/store/Globals';
import ColumnOrderMoveEvent from '@/store/models/ColumnOrderMoveEvent';
import { FuelFilters, SavedFuelFilters } from '@/store/models/Filters';
import { Product } from '@/store/models/Product';
import SortingInfo from '@/store/models/SortingInfo';
import TableColumn from '@/store/models/TableColumn';
import CustomStore from '@/store/modules/CustomStore';
import ProductStore from '@/store/modules/ProductStore';
import {
  adjustAndSetColumnOrder,
  orderColumnsBySavedOrder,
  removeSavedColumnOrder,
  sortDefaultAlertsColumns
} from '@/utils/ColumnOrdering';
import {
  GetVisibleEntityCustomColumns,
  GetVisibleSiteCustomColumns
} from '@/utils/CustomColumns';
import {
  getGroupedProducts,
  getProductMap
} from '@/utils/formatters/EnititesFormatter';
import { getGroupedFuelProducts } from '@/utils/formatters/FuelEntitiesFormatter';
import { getHasNonFonterra } from '@/utils/formatters/MilkEntitiesFormatter';
import {
  getAppDefaultTableFields,
  getAppFilter,
  getAppTableColumnFormatter,
  getVisibleAppTableColumnFormatters
} from '@/utils/GenericUtils';
import {
  getListItem,
  getObjectItem,
  setObjectItem
} from '@/utils/LocalStorageUtils';
import { sortAlerts } from '@/utils/SortingUtils';
import { feedTableColumnsFormatters } from '@/utils/table-fields/FeedTableFields';
import { fuelTableColumnsFormatters } from '@/utils/table-fields/FuelTableFields';
import { milkSpecificTableColumnsFormatters } from '@/utils/table-fields/MilkTableFields';
import { getPageTableFieldList } from '@/utils/table-fields/TableFieldLists';
import { tableColumnFormatters } from '@/utils/table-fields/TableFields';
import {
  AllowedField,
  FeedAllowedField,
  FuelAllowedField,
  MilkAllowedField
} from '@/utils/types/AllowedFields';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

@Component({
  components: {
    TableSearchbar,
    SideContent,
    AlertsCardList,
    FuelFiltersModal,
    MilkFiltersModal,
    FeedFiltersModal,
    ListView,
    DraggablePanel,
    AlertsFilters,
    LastUpdated,
    Searchbar,
    MobileHeader
  },
  computed: {
    allProducts: () => ProductStore.currentAllProducts ?? [],
    customFields: () => CustomStore.customFields ?? [],
    customEntityFields: () => CustomStore.customEntityColumns ?? []
  }
})
export default class AlertsView extends mixins(ConstantsMixin, AppName) {
  public products: Map<any, Product> = new Map();
  public tanks: Product[] = [];
  public sortBy = 'siteName';
  public searchTerm = '';
  private selectedProductId: number | null = null;
  public entityState: Product | null = null;
  public increasing = true;
  public loading = true;
  public selected = false;
  public sidebarPos = 0;
  public entityIndex = -1;
  public filters = getAppFilter();
  public width = 1000;
  public columns: TableColumn[] = [];
  public customColumns: TableColumn[] = [];
  public customEntityColumns: TableColumn[] = [];

  public showAll = true;
  public stateFilter: string[] = ['unactioned', 'actioned'];
  public updatedFilters = false;
  public activeSearch = false;
  public tableColumns: any[] = [];
  public sortInfo: SortingInfo = {
    sortBy: null,
    increasing: false
  };
  public offset = 0;
  public perPageLimit = 100;
  public sortByCustom = false;
  public lastUpdatedTimestamp: number | null = null;

  get paginatedProducts() {
    return [...this.tanks].splice(this.offset, this.perPageLimit);
  }

  get selectedProduct() {
    if (this.selectedProductId) {
      ProductStore.updateCurrentProductState(
        this.products.get(this.selectedProductId)
      );
      return this.products.get(this.selectedProductId);
    }
    return null;
  }

  get storeLoadingState() {
    return storeState.loading;
  }

  mounted() {
    trackEvent('User viewing fuel alerts');
    if (!this.storeLoadingState) {
      this.loadPageFields();
    }
    this.updateWidth();
  }

  created() {
    GetVisibleSiteCustomColumns().then(data => {
      this.customColumns = data;
    });
    GetVisibleEntityCustomColumns().then(data => {
      this.customEntityColumns = data;
    });

    this.$root.$on(['customFieldsUpdated', 'alertsUpdated'], () => {
      this.loadPageFields();
    });
    this.$root.$on('setEntityIndex', this.entitySelected);
    this.$root.$on('offsetChanged', this.changeOffset);
    this.$root.$on(
      'columnOrderChanged',
      (move: ColumnOrderMoveEvent, columns: any[]) => {
        this.tableColumns = columns;
        adjustAndSetColumnOrder(move);
      }
    );
    this.$root.$on('orgIndexChanged', this.resetOffset);
    window.addEventListener('resize', this.updateWidth);
  }

  destroyed() {
    window.removeEventListener('resize', this.updateWidth);
  }

  @Watch('showAll')
  handleShowAll() {
    this.filterProducts();
  }

  @Watch('stateFilter', { deep: true })
  handleStateFilter() {
    this.filterProducts();
  }

  @Watch('storeLoadingState')
  storeStateUpdated() {
    if (!this.storeLoadingState) {
      this.loadPageFields();
    }
  }

  @Watch('filters.filters', { deep: true })
  handleFilter() {
    this.checkFiltersUpdated();
    this.filterProducts();
  }

  public async updateData() {
    this.loadAllEntities();
  }

  public async loadPageFields() {
    await this.loadSavedPreferences();
    await this.loadTableColumns();
    this.filterProducts();
    this.products = getGroupedProducts(ProductStore.currentAllProducts ?? []);
    this.lastUpdatedTimestamp = dayjs().unix();
  }

  public async loadTableColumns() {
    const columns = await getVisibleAppTableColumnFormatters(
      getAppDefaultTableFields(getPageTableFieldList()),
      true
    );
    this.tableColumns = orderColumnsBySavedOrder(
      sortDefaultAlertsColumns(columns)
    );
    this.loadDefaultSortKey();
  }

  public async loadSavedPreferences() {
    const sortBy = getObjectItem(`${this.getAppName(false)}alertsSortBy`);
    if (sortBy && sortBy.sortBy) {
      const column = await getAppTableColumnFormatter(sortBy.sortBy.name);
      sortBy.sortBy = column;

      this.sortInfo = sortBy;
      this.sortByCustom = true;
    }
  }

  public loadDefaultSortKey() {
    if (this.sortInfo.sortBy == null || this.sortByCustom != true) {
      let savedAlertType = getListItem('milkAlertType');
      if (!savedAlertType || !(savedAlertType.length > 0)) {
        savedAlertType = ['score'];
      }
      if (this.isApp(this.PRODUCT_TYPE_FUEL)) {
        this.sortInfo.sortBy =
          fuelTableColumnsFormatters[FuelAllowedField.ALERT_TIME];
      } else if (this.isApp(this.PRODUCT_TYPE_FEED)) {
        this.sortInfo.sortBy =
          feedTableColumnsFormatters[FeedAllowedField.ALERT_TIME];
      } else {
        this.sortInfo.sortBy =
          this.isApp(this.PRODUCT_TYPE_MILK) && savedAlertType.includes('age')
            ? milkSpecificTableColumnsFormatters[MilkAllowedField.AGE] ?? null
            : tableColumnFormatters[AllowedField.WARNINGS] ?? null;
      }
    }
  }

  private async loadAllEntities() {
    this.loading = true;
    await ProductStore.fetchAllEntityStates(this.getAppName(false));
    this.lastUpdatedTimestamp = dayjs().unix();
    this.loading = false;
  }

  public updateWidth() {
    this.width = window.innerWidth;
  }

  public productSelectedFromList(
    selectedProduct: Product,
    index: number
  ): void {
    const actualProductWithEntities =
      this.products.get(selectedProduct.productId) ?? null;
    const actualEntityIndex =
      actualProductWithEntities?.entities?.findIndex(
        entity => entity.entityId == selectedProduct.entityId
      ) ?? 0;
    if (selectedProduct) {
      const prevSelect = this.selectedProductId;
      const prevEntitySelect = this.entityIndex;
      if (
        prevSelect === selectedProduct.productId &&
        prevEntitySelect === actualEntityIndex
      ) {
        this.selected = false;
        this.selectedProductId = null;
        this.entityState = null;
        this.entityIndex = -1;
        ProductStore.updateCurrentEntityIndexState(-1);
      } else {
        this.selected = true;
        this.entityIndex = actualEntityIndex;
        this.entityState = actualProductWithEntities;
        ProductStore.updateCurrentProductState(
          actualProductWithEntities ?? undefined
        );
        ProductStore.updateCurrentEntityIndexState(actualEntityIndex);
        this.selectedProductId = selectedProduct.productId;
      }
    }
  }

  public changeOffset(isPrevious: boolean | null, exactPage: number) {
    if (isPrevious == null) {
      this.offset = exactPage * this.perPageLimit;
    } else {
      this.offset += isPrevious ? -this.perPageLimit : this.perPageLimit;
    }
  }

  public resetOffset() {
    this.changeOffset(null, 0);
  }

  public updateSortKey(newSortInfo: SortingInfo) {
    this.sortInfo = newSortInfo;
    setObjectItem(`${this.getAppName(false)}alertsSortBy`, this.sortInfo);
    this.sortByCustom = true;
    this.tanks = sortAlerts([...this.tanks], this.sortInfo);
  }

  public entitySelected(entityIndex: number) {
    ProductStore.updateCurrentEntityIndexState(entityIndex);
    this.entityIndex = entityIndex;
  }

  public search(searchTerm: string): void {
    this.searchTerm = searchTerm.toString();
    this.filterProducts();
  }

  public filterProducts() {
    // filter on search term
    let filtered = ProductStore.currentAllProducts ?? [];
    if (filtered) {
      this.filters.setMaxRanges(filtered);
      setHasNonFonterra(getHasNonFonterra(filtered));
      filtered = this.filters.filterAlerts(
        filtered,
        this.stateFilter,
        this.showAll
      );
      filtered = this.filters.filterSearch(
        filtered,
        this.searchTerm,
        this.tableColumns
      );
      filtered = this.filters.filterProducts(filtered);

      this.checkFiltersUpdated();
      this.tanks = sortAlerts(
        Array.from(getProductMap(filtered, 'entityId')).map(
          product => product[1]
        ),
        this.sortInfo
      );
      this.loading = false;
    }
  }

  public applyCustomFilters(
    saveFilters: SavedFuelFilters,
    filters: FuelFilters,
    save: boolean
  ) {
    this.filters.filters = filters;
    trackEvent(`User applied filters to ${this.getAppName(false)} alerts`, {
      filters: JSON.stringify(filters)
    });
    if (save) {
      this.saveFilters(saveFilters);
    }
  }

  public checkFiltersUpdated() {
    this.updatedFilters = this.filters.filtersSet;
  }

  public updateAlertsFilters(showAll: boolean, stateFilter: string[]) {
    this.loading = true;
    this.showAll = showAll;
    this.stateFilter = stateFilter;
    trackEvent(
      `User applied alerts filters to ${this.getAppName(false)} alerts`,
      {
        showAll: showAll.toString(),
        stateFilter: JSON.stringify(stateFilter)
      }
    );
    this.loadDefaultSortKey();
    this.filterProducts();
  }

  public saveFilters(filters: SavedFuelFilters): void {
    setObjectItem(`${this.getAppName(false)}Filters`, {
      searchTerm: this.searchTerm,
      filters: filters
    });
    trackEvent(`User saved ${this.getAppName(false)} filters`);
    this.$bvToast.toast(
      'Your current filter selection has been saved to your browser',
      {
        title: 'Filters Saved',
        toaster: 'b-toaster-bottom-center',
        solid: true,
        append: false
      }
    );
  }

  public loadGroupedProducts() {
    this.products = getGroupedFuelProducts(
      ProductStore.currentAllProducts ?? []
    );
  }

  public resetColumnOrder(): void {
    removeSavedColumnOrder();
    this.loadTableColumns();
  }

  public closeSidebar() {
    this.selected = false;
    this.selectedProductId = null;
    this.entityIndex = -1;
  }

  public openFiltersModal() {
    this.$root.$emit(
      'bv::show::modal',
      `${this.getAppName(false)}FiltersModal`,
      '#btnShow'
    );
  }

  public updateActiveSearch(value: boolean) {
    this.activeSearch = value;
  }
}
