
import RepairIcon from '@/components/common/RepairIcon.vue';
import CustomField from '@/store/models/CustomField';
import { Product } from '@/store/models/Product';
import TableColumn from '@/store/models/TableColumn';
import { mixins } from 'vue-class-component';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    RepairIcon
  }
})
export default class FeedAlertsCardList extends mixins(AppName) {
  @PropSync('items') sortedProducts!: Product[];
  @Prop() loadingProducts!: boolean;

  public didSelectCard(selectedItems: Product[], id: number, entityId: number) {
    this.$emit('card-selected', selectedItems, id, entityId);
  }

  public openActionModal(product: Product) {
    this.$emit('openActionModal', product);
  }

  public openEditModal(product: Product) {
    this.$emit('openEditModal', product);
  }
}
