
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import RepairIcon from '@/components/common/RepairIcon.vue';

import { Product } from '@/store/models/Product';

@Component({ components: { RepairIcon } })
export default class WaterAlertsCardList extends Vue {
  @PropSync('items') sortedProducts!: Product[];
  @Prop() maxTank!: number;
  @Prop() loadingProducts!: boolean;

  public didSelectCard(selectedItems: Product[], id: number, entityId: number) {
    this.$emit('card-selected', selectedItems, id, entityId);
  }

  public openActionModal(product: Product) {
    this.$emit('openActionModal', product);
  }

  public openEditModal(product: Product) {
    this.$emit('openEditModal', product);
  }
}
